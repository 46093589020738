"use client"; // Error components must be Client Components

import { Button, Container, Text } from "@wojo/ui";

export default function ErrorPage() {
    return (
        <Container
            style={{
                paddingTop: "var(--g-spacing-xl",
                paddingBottom: "var(--g-spacing-xxl",
            }}
            width="small"
        >
            <Text.Heading order="2" tag="h1">
                Something went wrong.
            </Text.Heading>
            <Button tag="a" href="/" variant="quiet">
                Home
            </Button>
        </Container>
    );
}
